import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { TerminosGuard } from './guards/terminos.guard';
import { LayoutComponent } from './pages/layout/layout.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  { path: '', redirectTo: 'admin/routing', pathMatch: 'full' },
   {
      path: '', component: LayoutComponent,
      children: [
        { path: 'admin/routing',
          loadChildren: () => import('./pages/routing/routing.module').then(m => m.RoutingModule),
          canActivate: [AuthGuard, TerminosGuard]
        },
        /*{ path: 'admin/home',
          loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
          canActivate: [AuthGuard, TerminosGuard]
        },*/
        { path: 'admin/clientes',
          loadChildren: () => import('./pages/clientes/clientes.module').then(m => m.ClientesModule),
          canActivate: [AuthGuard, TerminosGuard]
        },
        { path: 'admin/usuarios',
          loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
          canActivate: [AuthGuard, TerminosGuard]
        },
        { path: 'admin/choferes',
          loadChildren: () => import('./pages/choferes/choferes.module').then(m => m.ChoferesModule),
          canActivate: [AuthGuard, TerminosGuard]
        },
        { path: 'admin/depositos',
          loadChildren: () => import('./pages/depositos/depositos.module').then(m => m.DepositosModule),
          canActivate: [AuthGuard, TerminosGuard]
        },
        { path: 'admin/moviles',
          loadChildren: () => import('./pages/moviles/moviles.module').then(m => m.MovilesModule),
          canActivate: [AuthGuard, TerminosGuard]
        },
        { path: 'admin/lotes',
          loadChildren: () => import('./pages/lotes/lotes.module').then(m => m.LotesModule),
          canActivate: [AuthGuard, TerminosGuard]
        },
        { path: 'admin/entregas',
          loadChildren: () => import('./pages/entregas/entregas.module').then(m => m.EntregasModule),
          canActivate: [AuthGuard, TerminosGuard]
        },
        { path: 'admin/visitas',
          loadChildren: () => import('./pages/visitas/visitas.module').then(m => m.VisitasModule),
          canActivate: [AuthGuard, TerminosGuard]
        },
        { path: 'admin/my-profile',
        loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
        canActivate: [AuthGuard, TerminosGuard]
        }
      ]
   },

  { path: '**',  redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
