import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { EditComponent } from '../../pages/users/edit/edit.component';
// services
import { GlobalService } from '../../services/global/global.service';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  public user_id: number = 0;
  public token: string;
  public user: any;
  constructor( private _authService: AuthService,
               private _globalService: GlobalService,
               private _userService: UserService,
               private _dialog: MatDialog,
               private route: Router)
  {
  this.user_id = JSON.parse(localStorage.getItem("currentUser"))?.id;
  this.token = JSON.parse(localStorage.getItem("currentUser"))?.access_token;
  }

  logout() {
    this._authService.logout()
    .subscribe((response:any) => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      this.route.navigateByUrl('/auth/login');
      this._globalService.showToast("Ha cerrado sesión correctamente", "success");
    }, (err) => {
      console.error(err);
      this._globalService.showToast("No se pudo cerrar la sesión", "error");
    });
  }
}
