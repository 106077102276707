<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#">
        <i class="fas fa-bars"></i>
      </a>
    </li>
  </ul>

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <a routerLink = "admin/my-profile"
      routerLinkActive="active"
      class="nav-link" data-widget="control-sidebar" data-slide="true" matTooltip="Mi perfil">
        <i class="fa fa-user-circle"></i>
      </a>
    </li>
    <!-- Notifications Dropdown Menu -->
    <li class="nav-item">
      <a class="nav-link" data-widget="control-sidebar" data-slide="true" matTooltip="Cerrar sesión" (click)="logout()">
        <i class="fas fa-sign-out-alt"></i>
      </a>
    </li>
  </ul>
</nav>
