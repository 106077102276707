import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
export class CustomRequest {

  public static jwt(socket: boolean = false) {
    // parseo a json del usuario que tengo guardado local storage
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let params = new HttpParams(); // instancio el objeto parametros
    let headers: HttpHeaders; // instancio el objecto cabeceras
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // si existe un usuario en local storage y su token
    if (!socket){
      if (currentUser) {
        headers = new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            'Authorization': currentUser.token_type + ' ' + currentUser.access_token,
          },
        ); // creo el header con el token
      } else {
          headers = new HttpHeaders(
            {
              'Content-Type': 'application/json'
            }
          );
      }
    } else {
      headers = new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept' : 'application/json',
        }
      );
    }

    return { headers: headers, params: params };

  }

}
