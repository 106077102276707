import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global/global.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {
  public menuOpts:any[] = [];
  public nameUser:any = "";
  public positions_delete:any[] = [];
  public permissions:any[];
  constructor( private _changeDetectorRef: ChangeDetectorRef,
               private _globalService: GlobalService,
               private _userService: UserService ) { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.nameUser = user.name
    this._globalService.getMenuOpts()
    .subscribe(response => {
      this.menuOpts = response;
      this.getPermissions();
    });
  }

  getPermissions() {
    this.positions_delete = [];
    this._userService.getPermisos()
      .subscribe((response:any[]) => {
        this.permissions = response;
          for (let i = 0; i < this.menuOpts.length; i++) {
            let existe = false;
            const item = this.menuOpts[i];
            for (const permiso in this.permissions) {
              if (permiso === item.data) {
                existe = true;
              }
            }
            if (!existe && item.data != "HOME") {
              this.positions_delete.push(i);
            }
          }
        this.orderArrayPermisos();
        this.eliminarItemsMenu();
      })
  }

  orderArrayPermisos() {
    this.positions_delete.sort(function (a, b) {
      if (a < b) {
        return 1;
      }
      if (a > b) {
        return -1;
      }
      return 0;
    });
  }

  eliminarItemsMenu() {
    for (let index = 0; index < this.positions_delete.length; index++) {
      const position = this.positions_delete[index];
      this.menuOpts.splice(position, 1);
    }
  }

}
