import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { message_error } from '../config/constantes';
import { GlobalService } from '../services/global/global.service';
import { TerminosService } from '../services/terminos/terminos.service';

@Injectable({
  providedIn: 'root'
})
export class TerminosGuard implements CanActivate {

  constructor( private _terminosService: TerminosService,
               private _globalService: GlobalService,
               private route: Router ) {

  }

  canActivate():boolean {

    let currentUser:any = '';

    if (localStorage.getItem('currentUser')) {

      currentUser = JSON.parse(localStorage.getItem('currentUser'));

      this._terminosService.cumpleTerminosYCondiciones(currentUser.id)
      .subscribe(
        (data: any) => {
          if (!data && currentUser.role[0] != "SUPER ADMINISTRADOR") {
            this.route.navigateByUrl('/auth/terminos-condiciones');
            return false;
          }
        },
      (err:any) => {
        console.error(err);
        if (err.error.error_controlado) {
          this._globalService.showToast(err.error.error_controlado, "error");
        } else {
          this._globalService.showToast(message_error, "error");
        }

        this.route.navigateByUrl('/auth/terminos-condiciones');
        return false;
      });

      
    }

    return true;
  }
  
}
