<div *ngIf="loading$ | async" class="loading">
  <ngx-loading
    [show]="loading$ | async"
    [config]="{
      animationType: ngxLoadingAnimationTypes.doubleBounce,
      primaryColour: primaryColour,
      secondaryColour: secondaryColour,
      backdropBorderRadius: '3px'
    }"
    [template]="loadingTemplate"
  >
  </ngx-loading>
</div>
