import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CustomRequest } from '../custom.request';
import { map, tap } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl = environment.apiUrl + 'users/';
  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService
  ) {}

  // get by filter filter: any
  getByFilter(filter: any) {
    const customRequest = CustomRequest.jwt();
    const params = customRequest.params
      .append('search', filter.search)
      .append('role', filter.role)
      .append('page', filter.page)
      .append('sort_by', filter.sort_by);

    const options = { headers: customRequest.headers, params: params };

    return this._http
      .get(this.apiUrl + 'get-by-filter', options)
      .pipe(map((response: any) => response.data));
  }

  // get all users
  getAll(): Observable<any> {
    return this._http.get(this.apiUrl + 'get-all', CustomRequest.jwt());
  }

  // create user
  create(create: User) {
    return this._http
      .post(this.apiUrl + 'create', create, CustomRequest.jwt())
      .pipe(map((response: any) => response.data));
  }

  // update user
  update(update: User, id: number) {
    return this._http
      .put(this.apiUrl + 'update/' + id, update, CustomRequest.jwt())
      .pipe(map((response: any) => response.data));
  }
  // update user
  updateMe(update: User, id: number) {
    return this._http
      .put(this.apiUrl + 'update-me/' + id, update, CustomRequest.jwt())
      .pipe(map((response: any) => response.data));
  }

  // delete user
  delete(id: number): Observable<any> {
    return this._http.delete(this.apiUrl + 'delete/' + id, CustomRequest.jwt());
  }

  getById(id: string): Observable<any> {
    return this._http
      .get(this.apiUrl + 'show/' + id, CustomRequest.jwt())
      .pipe(map((response: any) => response.data));
  }

  getMeId(id: string): Observable<any> {
    return this._http
      .get(this.apiUrl + 'me/' + id, CustomRequest.jwt())
      .pipe(map((response: any) => response.data));
  }

  // get permisos user loguin
  getPermisos() {
    return this._http
      .get(this.apiUrl + 'permisos', CustomRequest.jwt())
      .pipe(map((response: any) => response.data));
  }
  // get permisos for user
  getPermisosForUser(id_user) {
    return this._http
      .get(this.apiUrl + 'permisos-for-user/' + id_user, CustomRequest.jwt())
      .pipe(map((response: any) => response.data));
  }

  // update permissions user
  updatePermissionsUser(permisos: any, id: number) {
    let data = { data: permisos };
    return this._http.put(
      this.apiUrl + 'update-permissions-user/' + id,
      data,
      CustomRequest.jwt()
    );
  }
}
