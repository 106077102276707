<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <span class="brand-link content-logo">
        <img src="assets/adminlte/dist/img/AdminLTELogo.png" 
            alt="Logo empresa" 
            class="brand-image logo-ruteo">
        <span class="brand-text font-weight-light">| TecSolutions</span>
    </span>

    <!-- Sidebar -->
    <div class="sidebar">

        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" 
                data-widget="treeview" 
                role="menu" 
                data-accordion="false">
                <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
               <li class="nav-item" *ngFor="let menu of menuOpts">
                    <a [routerLink]="menu.redirectTo" 
                       routerLinkActive="active"
                       class="nav-link">
                        <i class="nav-icon" [ngClass]="menu.icon"></i>
                        <p>
                            {{ menu.name }}
                        </p>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>