import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CustomRequest } from '../custom.request';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiUrl;
  constructor(private _http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    return this._http.post(`${this.apiUrl}oauth/token`, ({ email: email, password: password }));
  }

  sendResetLinkEmail(email: string): Observable<any> {
    return this._http.post(`${this.apiUrl}oauth/resetpass`, ({ email: email }), CustomRequest.jwt());
  }

  newpassword(user:any): Observable<any> {
    return this._http.post(`${this.apiUrl}oauth/reset`, user, CustomRequest.jwt());
  }

  logout() {
    return this._http.post(`${this.apiUrl}oauth/logout`, null, CustomRequest.jwt());
  }


  autenticated():boolean {
    let token:string = '';
    if (localStorage.getItem('token')) {
      token = localStorage.getItem('token');

      if (token.length > 2 && token != "null") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
