// loading.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private activeRequests = 0;
  constructor(private loadingService: LoadingService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.show();

    return next.handle(req).pipe(
      finalize(() => {
        this.hide();
      })
    );
  }

  show(): void {
    this.activeRequests++;
    console.log('Loading shown, active requests:', this.activeRequests);
    this.loadingService.show();
  }

  hide(): void {
    if (this.activeRequests > 0) {
      this.activeRequests--;
    }
    if (this.activeRequests === 0) {
      console.log('Loading hidden, no active requests');
      this.loadingService.hide();
    } else {
      console.log('Active requests remaining:', this.activeRequests);
    }
  }
}
