// export const config_CkEditor = {
//     toolbar: [
//         'heading',
//         '|',
//         'bold',
//         'italic',
//         'bulletedList',
//         'numberedList',
//     ],
// };
export const message_error:string = "Ocurrió un error desconocido, contáctese con el administrador del sistema";
export const error_de_permisos:string = "No posee los permisos suficientes para acceder";